<template>
	<div class="material_detail">
		<div class="mian">
			<div class="inner_nav clearfix">
				<div class="fl inner_nav_l">当前位置：<span @click="backFun">字体素材</span> > {{info.title}}</div>
			</div>
			<div class="material_detail_info clearfix">
				<div class="fl material_detail_info_img">
					<img :src="info.thumb" alt="">
				</div>
				<div class="fr material_detail_info_d">
					<div class="material_detail_info_one">
						<div class="info_name">{{info.title}}</div>
						<div class="info_colloct_box" v-if="info.is_collect != 1">
							<div class="info_colloct" @click.stop="jionColloct">
								<img src="../assets/images/detailsc.png" alt="">
								<!-- <span>加入收藏</span> -->
							</div>

							<div class="collection_show" v-if="isShow">
								<collection :collectionId="info.id" type="2" @closeFun.stop="closeFun"></collection>
							</div>
						</div>
						<div class="info_colloct" v-else @click="unColloct">
							<img width="16" height="16" src="../assets/images/sc_s.svg" alt="">
							<!-- <span style="color:#5957FF">已收藏</span> -->
						</div>
					</div>
					<div class="material_detail_info_two">
						<div class="info_dowmload" @click="info.is_dict==1?downAFun(info.dict_material_files):downFun(info.id)">源文件下载</div>
						<!-- is_down_cart==0是没加入  ==1是加入 -->
						<div class="info_shop"
							:style="{color:info.is_down_cart == 1?'#cccccc':'#5957FF',border:info.is_down_cart == 1?'solid 1px #cccccc':'solid 1px #5957FF'}"
							@click="jionFun(info.id)"
							v-if="info.is_dict!=1">
							{{info.is_down_cart == 1?'移出清单':'加入清单'}}
						</div>
						<!-- <p ></p> -->
					</div>
					<div class="material_detail_info_three">
						<div>素材分类：{{info.ml_cate}}</div>
						<div>素材格式：{{info.format}}</div>
						<div>文件大小：{{info.file_size}}</div>
						<div>推荐版本：{{info.version}}</div>
						<!-- <div>上传时间：{{info.update_time}}</div> -->
					</div>
					<div class="material_detail_info_four">
						<!-- <div class="info_vip_tip">升级素材会员，免费下载</div>
                        <router-link to="/vip" class="info_vip_to">前往获取></router-link> -->
						<img src="../assets/images/materialdetail.png" alt="" @click="jumpVip">

					</div>
					<div class="material_detail_info_five" v-if="$parent.userShow">{{userInfo.down_vip==1?'当前账号今日剩余免费下载次数':'当前账号剩余下载数量'}}：{{downloadNum||'0'}}</div>
					<div class="material_detail_info_five" v-else></div><!-- 占位 -->
					<div class="material_detail_info_six">
						<div class="info_share">
							<span>分享到：</span>
							<img @click="$share.toQQ(info.title)" src="../assets/images/detailqq.png" alt="">
							<img @click="$share.toWeibo(info.title)" src="../assets/images/detailweibo.png" alt="">
							<div class="wxShare">
								<img src="../assets/images/detailweixin.png" alt="">
								<div class="wxShare_box">
									<div>使用微信 扫一扫</div>
									<div id="qrcode" ref="qrcode"></div>
								</div>
							</div>
						</div>
						<div class="info_explain" @click="seeShare(7)">
							<img src="../assets/images/tishishuoming.png" alt="">
							<span>会员服务协议</span>
						</div>
					</div>
				</div>
			</div>
			<div class="material_detail_info_span">
				关键词：<span v-for="item in antistop" :key="item" @click="antFun(item)">{{item}}</span>
			</div>
			<!-- 相似推荐 -->
			<div>
				<div class="public_title">
					<div class="public_title_name">相似推荐</div>
					<router-link to="/material" class="public_title_more">查看更多</router-link>
				</div>
				<div class="daily clearfix">
					<!-- wow animate__animated animate__fadeInUp -->
					<div class="fl daily_li " v-for="(item,index) in recommendList" :key="index"
						@click="nowToFun(item.id)">
						<div class="daily_li_box">
							<div class="daily_thumb"><img v-lazy="item.thumb" alt=""></div>

							<div class="daily_li_mask"></div>
							<div class="daily_li_downLoad" @click.stop="item.is_dict==1?downAFun(item.dict_material_files):downFun(item.id)">
								下载
								<!-- <span></span>
                                <img src="../assets/images/icon_mrtj_download@2x.png" alt=""> -->
							</div>
							<div class="daily_li_collect" :class="item.is_dict != 1?'':'daily_li_collects'" v-if="item.is_collect == 0"
								@click.stop="collectFun(2,item.id,index)"><img src="../assets/images/weishoucang.svg"
									alt=""></div>
							<div class="daily_li_collect" :class="item.is_dict != 1?'':'daily_li_collects'" v-else @click.stop="uncollectFun(2,item.id,index)"><img
									src="../assets/images/icon_collection@2x.svg" alt=""></div>
							<template v-if="item.is_dict != 1">
								<div class="daily_li_shop" v-if="item.is_down_cart == 0" @click.stop="jionFun(item.id)"><img
									src="../assets/images/icon_add@2x.svg" alt=""></div>
								<div class="daily_li_shop" v-else @click.stop="jionFun(item.id)"><img
										src="../assets/images/addqingdan1.svg" alt=""></div>
							</template>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 侧边导航 -->
		<sidebar ref="downtotal"></sidebar>
		<div class="mask" v-if="isCollect" @click="closeMsg">
			<collection isMask="1" :collectionId="collId" type="2" @closeFun.stop="closeFun"></collection>
		</div>

		<!-- 购买字体素材 -->
		<el-drawer
		:visible.sync="isBuyNum"
		direction="ttb"
		:with-header="false"
		:destroy-on-close="true"
		>
			<buyNum
				:type="materialDownList.length-1"
				:list="materialDownList"
				@typeFun="typeFun"
				@orderinfo="order_info_no"
				@toclearFun="clearIntervalFun"
			></buyNum>
		</el-drawer>
		<!-- 收货信息 -->
		<el-drawer
		:visible.sync="isReceive"
		direction="ttb"
		:with-header="false"
		:destroy-on-close="true"
		>
			<Receive
				:type="Receivetype"
				:number="order_no"
				:tipnum="tipnum"
				@close="closeDrawer"
				@toNews="toNewsFun"
				@toIndexs="toIndex"
			></Receive>
		</el-drawer>
		<div class="bg" v-if="tipStatus"></div>
			<div class="tips_wrap" v-show="tipStatus">
			<div class="tips">
				<div class="tips_thumb">
				<!-- <img src="../assets/images/redeem_success1.png" v-if="type == 6" />
				<img src="../assets/images/redeem_success2.png" v-if="type == 10" /> -->
				</div>
				<div class="tips_h2">恭喜，购买成功</div>
				<div class="tips_p">可到我的主页查看</div>

				<div class="close" @click="tipStatusFun">
				<img src="../assets/images/close.svg" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	const wx = require('weixin-js-sdk')
	import QRCode from 'qrcodejs2'
	import sidebar from '../components/sidebar.vue'
	import collection from '../components/collection.vue'
	import buyNum from "../components/vipBuyNum.vue";
	import Receive from "../components/receivingPopup.vue";
	export default {
		name: "materialDetail",
		components: {
			sidebar,
			collection,
			buyNum,
			Receive
		},
		data() {
			return {
				id: '', //素材id
				unfold: true,
				navList: [],
				recommendList: [],

				info: {},
				antistop: [],

				//收藏文件夹窗口
				isShow: false,

				collId: '', //收藏id
				isCollect: false,

				downloadNum: 0,

				userInfo:{},

				// 购买字体素材
				isBuyNum: false,
				materialDownList: [], //素材下载信息
				tipStatus: false,

				isReceive: false,
				Receivetype: 500,
				tipnum: 0,

				order_no: "", //订单编号
		
			}
		},
		mounted() {
			// if (window.history && window.history.pushState) {
			//     history.pushState(null, null, document.URL);
			//     window.addEventListener('popstate', this.goPageBefore, false);
			// }
			this.$parent.routerIndex = 2;
			this.$parent.showIndex = 1;
			this.id = this.$route.query.id;
			this.detailFun(this.id);
			this.materialDownSiteIndex();
			if (localStorage.getItem('access_token')) {
				this.getDownloadFun();
				this.$refs.downtotal.listFun();
			}
			if (localStorage.getItem("userInfo")) {
				this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
			}
			this.$nextTick(() => {
				let qrcode = new QRCode('qrcode', {
					width: 100, // 设置宽度，单位像素
					height: 100, // 设置高度，单位像素
					text: window.location.href // 设置二维码内容或跳转地址
				})
			})
		},
		methods: {
			detailFun(id) {
				var that = this;
				var params = {
					id: id,
				}
				this.$api.POST(this.$face.materialInfo, params, function(res) {
					that.info = res.data;
					that.recommendList = res.data.recommend;
					var label = '';
					if (res.data.label.indexOf('，') != -1) {
						label = res.data.label.replace(/，/g, ',');
					} else {
						label = res.data.label
					}
					that.antistop = label.split(',');
				})
			},

			// 获取下载次数
			getDownloadFun() {
				var that = this;
				this.$api.POST(this.$face.downloadZipdowncount, '', function(res) {
					that.downloadNum = res.data.zt_num
				})
			},

			// 下载源文件
			downFun(id) {
				if (localStorage.getItem('access_token')=='' || localStorage.getItem('access_token')==undefined || localStorage.getItem('access_token')==null) {
					this.$parent.loginShow();return ;
				}
				var that = this;
				var params = {
					id: id,
				}
				this.$api.POST(this.$face.downloadDown, params, function(res) {
					if(res.data.down_code == 100){
						if(that.userInfo.is_lifelong_vip == 1){
							that.$message({
								message: "您已经是终身会员",
								type: "error",
							});
							return;
						}
						if(that.userInfo.down_vip == 1 &&  that.dealClassStyle(that.userInfo.down_end_time) == 1){
							that.$message({
								message: "您已经是素材会员",
								type: "error",
							});
							return;
						}
						that.$message({
							message: res.data.msg,
							type: "error",
						});
						that.isBuyNum = true;
						if (!that.timer) {
							that.timer = window.setInterval(() => {
								//执行内容
								setTimeout(that.searchOrder(), 0);
							}, 5000);
						}
						return ;
					}else{
						const a = document.createElement('a')
						//   let url = baseUrl + binding.value // 若是不完整的url则需要拼接baseURL
						a.href = res.data.down_url // 完整的url则直接使用
						// document.body.appendChild(a)
						a.click()
						that.getDownloadFun();
						that.$parent.getUser('')
						that.$utile.template('success', '通知', res.data.msg);
					}
				})
			},

			downAFun(url){
				// const a = document.createElement('a')
				// //   let url = baseUrl + binding.value // 若是不完整的url则需要拼接baseURL
				// a.href = url // 完整的url则直接使用
				// // a.download = ''
				// a.setAttribute('download', '')
				// a.setAttribute('target', '_blank')
				// document.body.appendChild(a)
				// a.click()
				// this.$parent.getUser('')
				// this.$utile.template('success', '通知', '下载成功');
				let image = new Image()
				image.setAttribute('crossOrigin', 'anonymous')
				image.src = url
				image.onload = () => {
					let canvas = document.createElement('canvas')
					canvas.width = image.width
					canvas.height = image.height
					let ctx = canvas.getContext('2d')
					ctx.drawImage(image, 0, 0, image.width, image.height)
					canvas.toBlob((blob) => {
						let url = URL.createObjectURL(blob)
						let eleLink = document.createElement('a')
						eleLink.download = ''
						eleLink.href = url
						eleLink.click()
						eleLink.remove()
						// 用完释放URL对象
						URL.revokeObjectURL(url)
					})
				}
			},

			//加入清单
			jionFun(id) {
				if (localStorage.getItem('access_token')=='' || localStorage.getItem('access_token')==undefined || localStorage.getItem('access_token')==null) {
					this.$parent.loginShow();return ;
				}
	
	


				var that = this;
				var params = {
					material_id: id,
					type: 1,
				}
				this.$api.POST(this.$face.download_cartJoin, params, function(res) {
					that.$utile.prompt('success', res.msg);
					that.detailFun(that.id);
					that.$refs.downtotal.listFun();
				})
			},

			//收藏
			jionColloct() {
				if (localStorage.getItem('access_token')) {
					
					this.isShow = true;
					document.addEventListener("click", this.closeMsg);
				} else {
					this.$parent.loginShow()
				}
			},
			//取消收藏
			unColloct() {
				if (localStorage.getItem('access_token')=='' || localStorage.getItem('access_token')==undefined || localStorage.getItem('access_token')==null) {
					this.$parent.loginShow();return ;
				}
	
			
				var that = this;
				var params = {
					coll_id: this.info.id,
					type: 2,
				}
				this.$api.POST(this.$face.collectionColl, params, function(res) {
					that.info.is_collect = 0;
					that.$utile.prompt('success', '取消收藏');
				})
			},


			//关闭收藏
			closeFun(e) {
				this.isShow = false;
				this.isCollect = false;
				document.removeEventListener("click", this.closeMsg);
				if (e != 1) {
					this.detailFun(this.id)
				}
			},
			closeMsg(ev) {
				console.log(1)
				let dom = document.getElementById("child");
				if (dom) {
					if (!dom.contains(ev.target)) {
						this.isShow = false;
						this.isCollect = false;
					}
				}
				document.removeEventListener("click", this.closeMsg);
			},

			// 推荐收藏列表
			collectFun(type, id, index) {
				if (localStorage.getItem('access_token')) {
					this.type = type;
					this.collId = id;
					this.isCollect = true;
				} else {
					this.$parent.loginShow()
				}
			},
			// 推荐取消收藏
			uncollectFun(type, id, index) {
				var that = this;
				var params = {
					coll_id: id,
					type: type,
				}
				this.$api.POST(this.$face.collectionColl, params, function(res) {
					that.recommendList[index].is_collect = 0;
					that.$utile.prompt('success', '取消收藏');
				})
			},
			jumpVip(){
				let routeUrl = this.$router.resolve({
					name: "vip"
				});
				window.open(routeUrl.href, '_blank');
			},
			// 查看推荐详情
			nowToFun(id) {
				// this.$router.push({
				// 	name: 'refresh',
				// 	query: {
				// 		id: id
				// 	}
				// })
				let routeUrl = this.$router.resolve({
					name: "materialDetail",
					query: {
						id: id
					}
				});
				window.open(routeUrl.href, '_blank');
			},

			// 协议
			seeShare(id) {
				let routeUrl = this.$router.resolve({
					name: "agreement",
					query: {
						id: id
					}
				});
				window.open(routeUrl.href, '_blank');
			},

			antFun(e) {
				//localStorage.setItem('clearAll', 3)
				localStorage.setItem('material_name', e)
				// this.$router.push({
				// 	name: 'refresh',
				// 	query: {
				// 		usernext: 'material'
				// 	}
				// })
				let routeUrl = this.$router.resolve({
					path: "/refresh",
					query: {
						usernext: 'material'
					}
				});
				window.open(routeUrl.href, '_blank');
			},
			backFun() {
				localStorage.setItem('clearAll', 1)
				// this.$router.push({
				// 	name: 'refresh',
				// 	query: {
				// 		usernext: 'material'
				// 	}
				// })
				let routeUrl = this.$router.resolve({
					path: "/refresh",
					query: {
						usernext: 'material'
					}
				});
				window.open(routeUrl.href, '_blank');
			},

			// goPageBefore(){
			//     this.$router.push({name:'material'})
			// } 

			// 获取素材下载信息
			materialDownSiteIndex(){
				var that = this;
				this.$api.POST(this.$face.material_down_site_index, "", function (res) {
					that.materialDownList = res.data;
				});
			},
			typeFun(type) {
				// this.type = type;
			},
			order_info_no(data) {
				this.order_no = data;
			},
			closeDrawer(i) {
				// 支付
				this.isBuy = false;
				// 支付完成后收货地址
				this.isReceive = false;
			},
			clearIntervalFun() {
				if (this.timer) {
					window.clearInterval(this.timer);
					this.timer = null;
				}
			},
			// 查询扫码支付
			searchOrder(type) {
				var that = this;
				var data = {
					order_no: that.order_no,
				};
				this.$api.POST(this.$face.member_material_order_orderpaytype, data, function (res) {
					console.log("查询扫码支付", res);
					if (res.data.is_pay == 1) {
					// 支付完成后收货地址
						if (that.timer) {
							window.clearInterval(that.timer);
							that.timer = null;
						}
						that.isBuyNum = false;
						if (res.data.is_exp == 0) {
							that.isReceive = true;
						} else {
							that.tipStatus = true;
						}
						that.getDownloadFun()
					}
				});
			},
			tipStatusFun(){
				this.tipStatus=false;
			},
			toNewsFun() {
				this.$parent.newsFun();
			},
			toIndex() {
				this.$router.push({
					name: "index",
				});
			},
		},
		watch: {
			'$parent.userShow'() {
				this.getDownloadFun();
			},
		},
		destroyed() {
			// window.removeEventListener('popstate', this.goPageBefore, false);
		},
	};
</script>

<style scoped>
	.material_screen {
		width: 100%;
		background: #ffffff;
	}

	.inner_nav {
		padding: 27px 0 17px;
	}

	.inner_nav_l {
		font-size: 12px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 12px;
		letter-spacing: 0px;
		color: #999999;
	}


	.material_detail_info {
		margin-bottom: 19px;
	}

	.material_detail_info_img {
		width: 883px;
		height: 584px;
		background: #ffffff;
		border-radius: 10px;
		position: relative;
	}

	.material_detail_info_img img {
		max-width: 100%;
		max-height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		border-radius: 10px;
	}

	.material_detail_info_d {
		width: 495px;
		height: 584px;
		background-color: #ffffff;
		border-radius: 10px;
		padding: 27px 24px 0 30px;
		box-sizing: border-box;
	}

	.material_detail_info_one {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 33px;
	}

	.material_detail_info_one .info_name {
		font-size: 24px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 24px;
		letter-spacing: 0px;
		color: #333333;
	}

	.material_detail_info_one .info_colloct {
		width: 40px;
		height: 40px;
		line-height: 38px;
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		letter-spacing: 0px;
		color: #333333;
		border-radius: 10px;
		border: solid 1px #eee;
		box-sizing: border-box;
		opacity: 0.9;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		position: relative;
	}

	.material_detail_info_one .info_colloct img {
		margin-right: 0px;
	}

	.material_detail_info_one .info_colloct:hover {
		color: #5957FF;
	}

	.info_colloct_box {
		position: relative;
	}

	.collection_show {
		position: absolute;
		left: 60px;
		top: -4px;
	}

	.material_detail_info_two {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 32px;
	}

	.info_dowmload {
		width: 292px;
		height: 50px;
		background-image: linear-gradient(0deg,
				rgb(89, 87, 255) 0%,
				#a9a8ffd3 100%);
		box-shadow: -3px 3px 6px 1px rgba(89, 87, 255, 0.28);
		border-radius: 10px;
		font-size: 18px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 50px;
		letter-spacing: 0px;
		color: #ffffff;
		text-align: center;
		cursor: pointer;
	}

	.info_shop {
		width: 136px;
		height: 50px;
		background-color: #ffffff;
		border-radius: 10px;
		border: solid 1px #5957FF;
		box-sizing: border-box;
		text-align: center;
		font-size: 16px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 50px;
		letter-spacing: 0px;
		color: #5957FF;
		cursor: pointer;
	}

	.info_shop:hover {
		color: #5957FF !important;
	}

	.material_detail_info_three {
		font-size: 12px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 12px;
		letter-spacing: 0px;
		color: #999999;
		padding-bottom: 32px;
	}

	.material_detail_info_three div {
		margin-bottom: 15px;
	}

	.material_detail_info_four {
		width: 100%;
		height: 40px;
		background-color: #f6f6f6;
		border-radius: 10px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		/* padding: 0 12px 0 14px; */
		box-sizing: border-box;
		margin-bottom: 19px;
	}
	.material_detail_info_four img{ cursor: pointer;}

	.info_vip_tip {
		display: flex;
		align-items: center;
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		letter-spacing: 0px;
		color: #666666;
	}

	.info_vip_to {
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		letter-spacing: 0px;
		color: #ff8d00;
	}

	.material_detail_info_five {
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 14px;
		height: 14px;
		letter-spacing: 0px;
		color: #999999;
		margin-bottom: 122px;
	}

	.material_detail_info_six {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.info_share {
		display: flex;
		align-items: center;
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		letter-spacing: 0px;
		color: #999;
	}

	.info_share img {
		margin-right: 12px;
	}

	.info_explain {
		display: flex;
		align-items: center;
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		letter-spacing: 0px;
		color: #999999;
		cursor: pointer;
	}

	.info_explain img {
		margin-right: 5px;
	}

	.material_detail_info_span {
		display: flex;
		align-items: center;
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 14px;
		letter-spacing: 0px;
		color: #333333;
		margin-bottom: 60px;
	}

	.material_detail_info_span span {
		margin-right: 28px;
		transition: 0.3s;
		cursor: pointer;
	}

	.material_detail_info_span span:hover {
		color: #5957FF;
		text-decoration: underline;
	}

	.list {
		margin-bottom: 86px;
	}

	.daily {
		margin: 34px -13.33px 45px;
	}

	.daily_li {
		margin: 0 13.33px 30px;
	}

	.daily_li_box {
		width: 330px;
		height: 218px;
		border-radius: 10px;
		overflow: hidden;
		position: relative;
	}

	.daily_li_mask {
		position: absolute;
		width: 100%;
		height: 100%;
		bottom: 0;
		left: 0;
		border-radius: 10px;
		background: rgba(0, 0, 0, .4);
		opacity: 0;
		z-index: 33;
		-webkit-transition: all .3s ease-in-out;
		-moz-transition: all .3s ease-in-out;
		transition: all .3s ease-in-out;
		cursor: default;
	}

	.daily_li_downLoad {
		position: absolute;
		top: 15px;
		left: 15px;
		width: 72px;
		height: 40px;
		background-color: #5957FF;
		border-radius: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 14px;
		letter-spacing: 0px;
		color: #ffffff;
		opacity: 0;
		z-index: 34;
		-webkit-transition: all .3s ease-in-out;
		-moz-transition: all .3s ease-in-out;
		transition: all .3s ease-in-out;
		cursor: pointer;
	}

	.daily_li_downLoad span {
		width: 1px;
		height: 18px;
		background-color: #ffffff;
		opacity: 0.38;
		margin: 0 8px;
	}

	.daily_li_collect,
	.daily_li_shop {
		position: absolute;
		width: 40px;
		height: 40px;
		top: 15px;
		right: 62px;
		z-index: 100;
		opacity: 0;
		-webkit-transition: all .3s ease-in-out;
		-moz-transition: all .3s ease-in-out;
		transition: all .3s ease-in-out;
		cursor: pointer;
	}
	.daily_li_collects{
		right: 15px;
	}

	.daily_li_shop {
		right: 15px;
	}

	.daily_li_collect img,
	.daily_li_shop img {
		width: 100%;
		height: 100%;
		-webkit-transition: all .3s ease-in-out;
		-moz-transition: all .3s ease-in-out;
		transition: all .3s ease-in-out;
	}

	.daily_li_collect:hover img,
	.daily_li_shop:hover img {
		opacity: 0.8;
	}

	.daily_li:hover .daily_li_mask {
		opacity: 1;
	}

	.daily_li:hover .daily_li_downLoad {
		opacity: 1;
	}

	.daily_li:hover .daily_li_collect {
		opacity: 1;
	}

	.daily_li:hover .daily_li_shop {
		opacity: 1;
	}

	/* 专题 */
	.project {
		margin: 28px -13.33px 40px;
	}

	.project_li {
		width: 330px;
		height: 300px;
		margin: 0 13.33px 30px;
		padding-top: 15px;
		background: url(../assets/images/bg_tjzt2x.png) no-repeat;
		background-size: 311px auto;
		background-position: center top;
	}

	.project_li_box {
		position: relative;
		overflow: hidden;
		border-radius: 10px;
		width: 330px;
		height: 300px;
	}

	.project_li_box>img,
	.video_li_box>img,
	.daily_li_box>img {
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		width: 100%;
		height: auto;
		-webkit-transition: all .3s ease-in-out;
		-moz-transition: all .3s ease-in-out;
		transition: all .3s ease-in-out;
	}

	.project_li_hot {
		position: absolute;
		top: 14px;
		left: -10px;
		width: 62px;
		height: 40px;
		background-color: #5957FF;
		border-radius: 10px;
		font-size: 14px;
		font-weight: bold;
		font-stretch: normal;
		line-height: 40px;
		text-align: center;
		letter-spacing: 0px;
		color: #ffffff;
		z-index: 101;
		transition: 0.5s;
		padding-left: 10px;
		box-sizing: border-box;
	}

	.project_li:hover .project_li_hot {
		left: 15px;
		padding-left: 0px;
	}

	.project_li_buttom {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 45px;
		text-align: left;
		padding: 0 16px;
		box-sizing: border-box;
		line-height: 45px;
		color: #FFF;
		font-size: 15px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		-webkit-border-radius: 0;
		border-radius: 0;
		background: rgba(0, 0, 0, .4);
		opacity: 1;
		z-index: 33;
		-webkit-transition: all .3s ease-in-out;
		-moz-transition: all .3s ease-in-out;
		transition: all .3s ease-in-out;
	}

	.project_li_mask {
		position: absolute;
		width: 100%;
		height: 100%;
		bottom: 0;
		left: 0;
		border-radius: 10px;
		background: rgba(0, 0, 0, .4);
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 15px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 36px;
		letter-spacing: 0px;
		color: #ffffff;
		opacity: 0;
		z-index: 34;
		-webkit-transition: all .3s ease-in-out;
		-moz-transition: all .3s ease-in-out;
		transition: all .3s ease-in-out;
		cursor: default;
	}

	.project_li_collect {
		position: absolute;
		width: 40px;
		height: 40px;
		top: 14px;
		right: 15px;
		z-index: 100;
		-webkit-transition: all .3s ease-in-out;
		-moz-transition: all .3s ease-in-out;
		transition: all .3s ease-in-out;
		opacity: 0;
		cursor: pointer;
	}

	.project_li_collect img {
		width: 100%;
		height: 100%;
		-webkit-transition: all .3s ease-in-out;
		-moz-transition: all .3s ease-in-out;
		transition: all .3s ease-in-out;
	}

	.project_li_collect:hover img {

		opacity: 0.8;
	}

	.project_li:hover .project_li_box>img,
	.video_li:hover .video_li_box>img,
	.daily_li:hover .daily_li_box>img {
		transform: scale(1.08) translateX(-46%);
	}

	.project_li:hover .project_li_buttom {
		opacity: 0;
	}

	.project_li:hover .project_li_mask,
	.project_li:hover .project_li_collect {
		opacity: 1;
	}

	/* 视频教程 */
	.video_list {
		margin: 28px -13.33px 45px;
	}

	.video_li {
		margin: 0 13.33px 30px;
	}

	.video_li_box {
		width: 330px;
		height: 218px;
		border-radius: 10px;
		position: relative;
		overflow: hidden;
	}

	.video_li_mask {
		position: absolute;
		width: 100%;
		height: 0;
		bottom: 0;
		left: 0;
		border-radius: 10px;
		background: rgba(0, 0, 0, .4);
		/* opacity: 0; */
		z-index: 33;
		-webkit-transition: all .3s ease-in-out;
		-moz-transition: all .3s ease-in-out;
		transition: all .3s ease-in-out;
		cursor: default;
	}

	.video_li_height {
		position: absolute;
		top: 15px;
		left: -100px;
		width: 72px;
		height: 40px;
		background-color: #5957FF;
		border-radius: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 14px;
		letter-spacing: 0px;
		color: #ffffff;
		opacity: 1;
		z-index: 34;
		-webkit-transition: all .3s ease-in-out;
		-moz-transition: all .3s ease-in-out;
		transition: all .3s ease-in-out;
		cursor: pointer;
	}

	.video_li_height span {
		width: 1px;
		height: 18px;
		background-color: #ffffff;
		opacity: 0.38;
		margin: 0 8px;
	}

	.video_li_collect {
		position: absolute;
		width: 40px;
		height: 40px;
		top: -70px;
		right: 15px;
		z-index: 100;
		-webkit-transition: all .3s ease-in-out;
		-moz-transition: all .3s ease-in-out;
		transition: all .3s ease-in-out;
		cursor: pointer;
	}

	.video_li_collect img {
		width: 100%;
		height: 100%;
		-webkit-transition: all .3s ease-in-out;
		-moz-transition: all .3s ease-in-out;
		transition: all .3s ease-in-out;
	}

	.video_li_play {
		position: absolute;
		top: 140%;
		left: 50%;
		z-index: 101;
		margin-left: -37.5px;
		margin-top: -37px;
		width: 74px;
		height: 75px;
		transition: all .3s ease-in-out;
		cursor: pointer;
	}

	.video_li_collect:hover img {
		opacity: 0.8;
	}

	.video_li:hover .project_li_buttom {
		opacity: 0;
	}

	.video_li:hover .video_li_mask {
		height: 100%;
	}

	.video_li:hover .video_li_height {
		top: 15px;
		left: 15px;
		opacity: 1;
		transition-delay: 0.3s;
	}

	.video_li:hover .video_li_collect {
		top: 15px;
		transition-delay: 0.4s;
	}

	.video_li:hover .video_li_play {
		top: 50%;
		transition-delay: 0.3s;
	}


	/* @media screen and (max-width: 1600px) {
        .project_li,.project_li_box,.video_li_box,.daily_li_box{
            width: 280px;
            height: 184.4px;
        }
        .project_li{
            background-size: 261px auto;
        }
        .entrance_nav_li{
            width: 280px;
        }
        .provide_li{
            width: 270px;
        }
        .member_box{
            width: 580px;
        }
    }
    @media screen and (max-width: 1400px) {
        .project_li,.project_li_box,.video_li_box,.daily_li_box{
            width: 240px;
            height: 158px;
        }
        .project_li{
            background-size: 221px auto;
        }
        .entrance_nav_li{
            width: 240px;
        }
        .provide_li{
            width: 230px;
        }
        .member_box{
            width: 500px;
        }
    } */

	.sidebar_r {
		right: 2%;
		left: auto;
	}

	.daily_li_box .daily_thumb {
		position: relative;
		height: 218px;
		transition: all .3s ease-in-out;
	}

	.daily_li_box .daily_thumb>img {
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
		width: 100%;
		height: auto;

	}

	daily_li_box:hover .daily_thumb {
		transform: scale(1.08);
	}
	.bg {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.6;
  z-index: 10001;
}


	.tips_wrap {
  background-color: #fff;
  border-radius: 10px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10002;
  width: 545px;
  height: 200px;
}
.tips {
  position: relative;
}
.tips_thumb {
  padding-top: 40px;
  width: 495rpx;
  margin: auto;
}
.tips_thumb img {
  display: block;
  margin: auto;
}
.tips_h2 {
  font-size: 20px;
  text-align: center;
  font-weight: bold;
  color: #595757;
  padding: 27px 0 10px;
}
.tips_p {
  font-size: 14px;
  color: #5e5c5c;
  text-align: center;
}
.close {
  position: absolute;
  right: 22px;
  top: 20px;
}
.close img {
  width: 15px;
  height: 15px;
  opacity: 0.6;
}

.material_detail >>> .el-drawer {
  height: 100% !important;
  box-shadow: 0 0px 0px 0px rgb(0 0 0 / 0%), 0 0px 0px 0px rgb(0 0 0 / 0%),
    0 0px 0px 0px rgb(0 0 0 / 0%) !important;
}
</style>
